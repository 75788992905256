<section id="footer">
    <div class="custom-shape-divider-top-1599607129">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-4 offset-sm-4">
                <ul class="social-list w-100 d-flex justify-content-around">
                    <li>
                        <a href="">
                            <i class="fab fa-facebook-f"></i>
                        </a>                      
                    </li>
                    <li>
                        <a href="">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fab fa-whatsapp"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-4 footer-box">
                <img src="./assets/logo_white.png" class="" alt="" />
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur iure aliquid, nostrum esse facere, hic quidem quisquam labore mollitia explicabo odit. Natus velit voluptatibus, nisi aperiam ab dignissimos dolor dolorem?</p>
            </div>
            <div class="col-sm-4 footer-box">
                <p><b>Contactanos</b></p>
                <p>San Pedro Garza García, Monterrey.</p>
                <p>Algun numero</p>
            </div>
            <div class="col-sm-4 footer-box">
                <p><b>Subscribe Newsletter</b></p> 
                <input type="text" class="form-control mb-3">
                <button class="btn btn-light">Enviar</button>            
            </div>
        </div>
        <hr>
        <p class="text-center"></p>
    </div>
</section>