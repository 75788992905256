<div id="home">
  <div class="banner">
    <div class="container">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div ngxSlickItem class="slide">
          <div class="row">
            <div class="col-sm-6 align-self-center">
              <h1 class="title-banner fidena-font">
                WE BUILD SCALABLE AND SMART SOLUTIONS
              </h1>
              <p class="subtitle mt-3">
                We are passionate about creating outstanding software solutions
                that create value for your business and lead to success.
              </p>
            </div>
            <div class="col-sm-6">
              <img
                src="./assets/mobile_preview.png"
                class="w-100 d-block mx-auto flip-img"
                alt=""
              />
            </div>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="row">
            <div class="col-sm-6">
              <img
                src="./assets/social_media.png"
                class="w-75 d-block mx-auto flip-img"
                alt=""
              />
            </div>
            <div class="col-sm-6 align-self-center">
              <h1 class="title-banner fidena-font text-uppercase">
                Social Media Marketing and Management Services
              </h1>
              <p class="subtitle mt-3">
                We are passionate about creating outstanding software solutions
                that create value for your business and lead to success.
              </p>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="row">
            <div class="col-sm-6 align-self-center">
              <h1 class="title-banner text-uppercase fidena-font">
                We are here to be your product design partner
              </h1>
              <p class="subtitle mt-3">
                We are passionate about creating outstanding software solutions
                that create value for your business and lead to success.
              </p>
            </div>
            <div class="col-sm-6">
              <img
                src="./assets/ux_ui.png"
                class="w-75 d-block mx-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
      <!---->
    </div>
    <div class="custom-shape-divider-bottom-1599600820">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
  <div id="services" class="py-4">
    <div class="container">
      <h1 class="title text-center">What We Do?</h1>
      <div
        class="row services text-center"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div class="col-sm-4">
          <img src="./assets/ui_ux.png" alt="" />
          <h4>UX | UI Design</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eveniet
            quam ipsum est numquam temporibus saepe nesciunt ex, eum qui,
            expedita sunt aliquam sed maiores.
          </p>
        </div>
        <div class="col-sm-4">
          <img src="./assets/web.png" alt="" />
          <h4>UX | UI Design</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eveniet
            quam ipsum est numquam temporibus saepe nesciunt ex, eum qui,
            expedita sunt aliquam sed maiores.
          </p>
        </div>
        <div class="col-sm-4">
          <img src="./assets/mobile.png" alt="" />
          <h4>UX | UI Design</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eveniet
            quam ipsum est numquam temporibus saepe nesciunt ex, eum qui,
            expedita sunt aliquam sed maiores.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="testimonials" class="py-4">
    <div class="container my-4">
      <h1 class="title text-center">Tesmimonios</h1>
      <div class="row">
        <div
          class="col-md-4 testimonial"
          data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div class="card">
            <div class="card-body">
              <img src="./assets/people_1.jpg" alt="" />
              <p class="user-detail">
                <b>Edilvia Cesar</b><br /><span class="subtitle-app"
                  >Directora de Mexspacio Inmobiliarios</span
                >
              </p>
              <p class="description">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Molestiae, tenetur cumque. Aperiam itaque ducimus error corrupti
                necessitatibus laudantium delectus quidem dolorum nostrum, aut
                laboriosam qui soluta eum nemo sequi in!
              </p>
              <p class="text-center">
                <a href="" class="btn app-btn rounded-pill"
                  >Ver caso de estudio <i class="fal fa-arrow-right ml-2"></i
                ></a>
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 testimonial"
          data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div class="card">
            <div class="card-body">
              <img src="./assets/people_2.jpg" alt="" />
              <p class="user-detail">
                <b>Yurixhi Zarazua</b><br /><span class="subtitle-app"
                  >Directora en
                  <a href="http://zarazuas.codelabs.com.mx" target="_blank"
                    >Zarazua's Photo</a
                  ></span
                >
              </p>
              <p class="description">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Molestiae, tenetur cumque. Aperiam itaque ducimus error corrupti
                necessitatibus laudantium delectus quidem dolorum nostrum, aut
                laboriosam qui soluta eum nemo sequi in!
              </p>
              <p class="text-center">
                <a href="" class="btn app-btn rounded-pill"
                  >Ver caso de estudio <i class="fal fa-arrow-right ml-2"></i
                ></a>
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 testimonial"
          data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div class="card">
            <div class="card-body">
              <img src="./assets/people_3.jpeg" alt="" />
              <p class="user-detail">
                <b>Alejandro Saldaña</b><br /><span class="subtitle-app"
                  >Director en Lindy Pharma</span
                >
              </p>
              <p class="description">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Molestiae, tenetur cumque. Aperiam itaque ducimus error corrupti
                necessitatibus laudantium delectus quidem dolorum nostrum, aut
                laboriosam qui soluta eum nemo sequi in!
              </p>
              <p class="text-center">
                <a href="" class="btn app-btn rounded-pill"
                  >Ver caso de estudio <i class="fal fa-arrow-right ml-2"></i
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
